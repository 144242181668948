<template>
  <el-card id="evictionPolicy">
    <header>
      <h1>回收策略</h1>
      <el-divider></el-divider>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>分配时间</span>
            <el-date-picker
              v-model="getTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="small"
              align="right"
            >
            </el-date-picker>
          </div>
          <div class="left-wrap">
            <span>部门选择</span>
            <el-select
              v-model="departId"
              placeholder="请选择部门"
              size="small"
              @change="departmentSel"
            >
              <el-option
                v-for="item in departList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="left-wrap">
            <span>是否拨打</span>
            <el-select
              v-model="value"
              placeholder="请选择拨打状态"
              size="small"
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-delete"
            @click="screenData"
            size="small"
            class="searchBtn"
            >回收</el-button
          >

          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset"
            size="small"
            class="reset"
            >重置</el-button
          >
        </div>
      </div>
    </header>
    <main>
      <!-- 状态 -->
      <div class="status">
        <div class="status-title">客户状态</div>
        <el-checkbox
          :indeterminate="isIndeterminateStstus"
          v-model="statusAll"
          @change="handlestatusAllChange"
          style="margin-right: 30px"
          >全选</el-checkbox
        >
        <el-checkbox-group v-model="status" @change="handlestatusChange">
          <el-checkbox v-for="city in cities" :label="city" :key="city.id">{{
            city.name
          }}</el-checkbox>
        </el-checkbox-group>
      </div>

      <!-- 人员 -->
      <div class="staff">
        <div class="staff-title" style="line-height: 30px">人员筛选</div>
        <div class="staff-list">
          <el-checkbox
            :indeterminate="isIndeterminateStstusB"
            v-model="statusAllB"
            @change="handlestatusAllChangeB"
            style="line-height: 30px"
            >全选</el-checkbox
          >
          <el-checkbox-group
            v-model="statusB"
            @change="handlestatusChangeB"
            style="line-height: 30px"
          >
            <el-checkbox v-for="city in citiesB" :label="city" :key="city.id">{{
              city.name
            }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
    </main>
    <!-- 备注弹出框 -->
    <el-dialog title="编辑备注" :visible.sync="causeVisble" width="40%">
      <div>
        <el-input
          type="text"
          autosize
          placeholder="请输入内容"
          v-model="causeData"
          style="margin-bottom: 25px"
        >
        </el-input>
      </div>
      <span class="dialog-footer causeShow">
        <el-button @click="causeVisble = false">取 消</el-button>
        <el-button type="primary" @click="causeSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import { phoneFindUser, phoneRecycle } from "@/api/phone";
import { sysDepartList } from "@/api/sys_depart";
import { userList } from "@/api/user";

const cityOptions = [
  { id: 0, name: "一般客户" },
  { id: 1, name: "意向客户" },
  { id: 2, name: "无意向客户" },
  { id: 5, name: "无状态" },
  { id: 6, name: "未接通" },
];

export default {
  data() {
    return {
      causeData: "",
      departList: [],
      statusAll: false,
      statusAllB: false,
      status: [],
      statusB: [],
      cities: cityOptions,
      allcityOptionsB: [],
      citiesB: [],
      isIndeterminateStstus: true,
      isIndeterminateStstusB: true,
      value: "",
      options1: [
        {
          value: "0",
          label: "已拨打",
        },
        {
          value: "1",
          label: "未拨打",
        },
      ],
      // 备注弹出框
      causeVisble: false,
      getTime: null,
      departId: null,
    };
  },
  created() {
    this.$nextTick(function () {
      this.getList();
      this.getDepartList();
    });
  },
  inject: ["reload"],
  methods: {
    // 状态 多选
    handlestatusAllChange(val) {
      this.status = val ? cityOptions : [];
      this.isIndeterminateStstus = false;
    },
    handlestatusChange(value) {
      let checkedCount = value.length;
      this.statusAll = checkedCount === this.cities.length;
      this.isIndeterminateStstus =
        checkedCount > 0 && checkedCount < this.cities.length;
    },
    // 人员 多选
    handlestatusAllChangeB(valB) {
      this.statusB = valB ? this.allcityOptionsB : [];
      this.isIndeterminateStstusB = false;
    },
    handlestatusChangeB(valueB) {
      let checkedCount = valueB.length;
      this.statusAllB = checkedCount === this.citiesB.length;
      this.isIndeterminateStstusB =
        checkedCount > 0 && checkedCount < this.citiesB.length;
    },
    // 重置
    reset() {
      this.reload();
    },
    // 人员 数据
    getList() {
      let res = phoneFindUser();
      res.then((res) => {
        let data = res.data.data;
        if (data.user) {
          data.user.forEach((v) => {
            this.allcityOptionsB.push({ name: v.realname, id: v.id });
          });
        }
        this.citiesB = this.allcityOptionsB;
      });
    },
    // 部门
    getDepartList() {
      let params = {
        page: 1,
        size: 30,
        topId: localStorage.getItem("id"),
      };
      let res = sysDepartList(params);
      res.then((res) => {
        let data = res.data.data;
        this.departList = data.map((v) => {
          return { id: v.id, name: v.departName };
        });
      });
    },
    // 补0
    fillZero(n) {
      return n <= 9 ? "0" + n : n;
    },

    // 获取年月日的函数
    getYmd(data) {
      let y = data.getFullYear(); //年
      let m = data.getMonth() + 1; //月
      let d = data.getDate(); //日
      return [y, this.fillZero(m), this.fillZero(d)].join("-");
    },
    // 获取时间的函数
    getHms(times) {
      let h = times.getHours(); //时
      let m = times.getMinutes(); //分
      let s = times.getSeconds(); //秒
      return [this.fillZero(h), this.fillZero(m), this.fillZero(s)].join(":");
    },
    // 回收
    screenData() {
      this.causeVisble = true;
    },
    // 备注确认事件
    causeSubmit() {
      let remarks = this.causeData;
      let listId = this.statusB.map((v, i) => {
        return this.statusB[i].id;
      });
      let sta = null;
      let end = null;
      //   console.log(this.statusB);
      //   console.log(this.status);
      if (this.getTime) {
        sta = this.getYmd(this.getTime[0]) + " " + this.getHms(this.getTime[0]);
        end = this.getYmd(this.getTime[1]) + " " + this.getHms(this.getTime[1]);
      }
      let status = this.status.map((v, i) => {
        return this.status[i].id;
      });

      let params = {
        listId,
        sta,
        end,
        status,
        remarks,
      };

      let noCall;
      if (this.value == "1") {
        //未拨打
        params.noCall = this.value;
      } else if (this.value == "0") {
        //已拨打
      } else {
        Message.warning("拨号状态不能为空");
      }
      //   console.log(params);
      let res = phoneRecycle(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.reload();
        } else {
          Message.warning(res.data.message);
        }
      });
    },
    // 部门选择
    departmentSel() {
      let params = {
        departId: this.departId,
        username: null,
      };
      let res = userList(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          let data = res.data.data;
          if (data) {
            this.allcityOptionsB = data.map((v, i) => {
              return { name: v.realname, id: v.user_id };
            });

            Message.success(res.data.message);
          } else {
            Message.warning("查询数据为空");
          }
          this.citiesB = this.allcityOptionsB;
        } else {
          Message.warning(res.data.message);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
#evictionPolicy {
  header {
    margin-bottom: 20px;
    h1 {
      margin-bottom: 40px;
      font-weight: normal;
    }
    .search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      //   flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          margin-right: 10px;
          span {
            margin: 10px;
            // width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        // margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    height: 100%;
    min-height: 520px;
    .staff-title {
      width: 10%;
    }
    .staff-list {
      flex: 1;
    }
    .staff-list-data {
      flex-wrap: wrap;
    }
    .status,
    .status-list,
    .staff,
    .staff-list-data {
      display: flex;
    }
    .status {
      align-items: center;
    }
    .status-title {
      width: 10%;
    }
    .status-list > label {
      align-items: center;
      margin-right: 30px;
    }
    .status {
      margin-bottom: 20px;
    }
  }
}
</style>